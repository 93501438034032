<template>
  <div eagle-form="block">
    <v-lazy>
      <v-card elevation="0" class="pa-0">
        <div v-if="title" class="d-flex justify-space-between">
          <h3 class="primary--text mb-2">{{ title }}</h3>

          <div v-if="block.titleSlot">
            <component :is="block.titleSlot" :form-key="formKey"></component>
          </div>
        </div>

        <v-divider></v-divider>

        <!--表單區塊help-->
        <v-card-subtitle v-if="help" class="secondary--text pa-0 my-3 ">
          {{ help }}
        </v-card-subtitle>

        <!-- <v-divider></v-divider> -->

        <!--表單資料-->
        <v-container>
          <component
            eagle-block-content
            :is="blockComponent"
            :block="block"
            :blockIndex="blockIndex"
            :blockReadonly="readonly"
            :blockDisabled="disabled"
            :blockData="blockData"
            :formKey="formKey"
          ></component>
        </v-container>
      </v-card>
    </v-lazy>
  </div>
</template>

<script lang="babel" type="text/babel">
import formBlockMixin from '@/components/form/mixins/formBlockMixin.js'
export default {
  mixins: [formBlockMixin],
  computed: {
    blockComponent() {
      // 自訂表單區塊元件
      if(this.block.component) {
        return this.block.component
      }

      return 'formBlockContent'
    },
  },
  components: {
    formBlockContent: () => import('@/components/form/formBlockContent.vue'),
  },
}
</script>

<style lang="sass" type="text/sass">
.v-application
  .linerp-layout
    .primary
      &.lighten-1
        background-color: #009775 !important
        border-color: #009775 !important

</style>
